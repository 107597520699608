<template>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="headline style-3">
          <h5>Biuro Storno</h5>
          <h2>O nas</h2>
        </div>
        <img src="images/o.jpg" width="237" height="237" style="float:right">
        <p>
          Biuro Rachunkowe Poznań "STORNO" powstało w 1992
          roku i specjalizuje się w obsłudze małych i średnich podmiotów
          gospodarczych.
        </p>
        <p>
          Biuro w pierwszym okresie działalności&nbsp;
          specjalizowało się w wyprowadzaniu zaległości księgowych.
          Zapotrzebowanie na tego typu usługi w tym czasie było dość znaczne,
          wiele nowopowstających firm nie potrafiło odnaleźć się w nowej
          rzeczywistości gospodarczej a próby prowadzenia ksiąg sposobem
          „chałupniczym”, przez właścicieli często kończyły się odpychaniem
          spraw związanych z prowadzeniem ksiąg na czas późniejszy co często
          kończyło się konfliktem z Urzędami Skarbowymi. Niezależnie od
          powyższego Biuro prowadziło bieżącą obsługę jednostek gospodarczych.
          Z dumą możemy stwierdzić, że są przedsiębiorstwa, których obsługę prowadzimy od samego początku naszej działalności gospodarczej.
        </p>
        <p>
          Właściciel biura rachunkowego, Jacek Bartczak,
          posiada wykształcenie wyższe ekonomiczne oraz wieloletnie
          doświadczenie w obsłudze księgowej podmiotów gospodarczych,
          kilkuletnią praktykę w Izbie Skarbowej w Poznaniu w dziale kontroli
          podatkowej, staż pracy na stanowisku głównego księgowego w kilku
          przedsiębiorstwach o różnym profilu działalności i jest wpisany na
          listę doradców podatkowych pod nr 05171.
        </p>
        <p>
          Biuro rachunkowe jest ubezpieczone i odpowiada za szkody wyrządzone przy wykonywaniu czynności obsługi księgowej, doradztwa podatkowego oraz spraw kadrowych.
          Usługi księgowe dla firm prowadzone są
          komputerowo w oparciu o programy zgodne z wymogami: Ustawy o
          rachunkowości, Ministerstwa Finansów oraz wszystkich innych
          przepisów.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  head: {
    title: 'O nas - Biuro rachunkowe Poznań "STORNO"'
  }
}
</script>
